import './ProductPrices.css';
import React from 'react';
import i18n from '../i18n';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaleSvg from '../svgs/saleSvg';
import Sale2Svg from '../svgs/sale2Svg';

class ProductPrices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.price && props.data ? props.price : props.data[0].price
    }
  }

  componentDidMount() {
    this.unmount = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unmount()
  }


  render() {
    let cur_price = this.props.price && this.props.data ? this.props.price : this.props.data[0].price
    return (
      <div className="ProductPrices">
        {
         
          <div className="price-pick">
            {
              this.props.data ?
              <ul className="prices-list">
                <li className='price-row header-prices'>
                  <ul>
                    <li>{i18n.t("from_qty_header")} ({i18n.t("pcs")})</li>
                    <li>{i18n.t("price_header")}</li>
                  </ul>
                </li>
              {
                this.props.data.map( (price,i) => {
                  return(
                    <li key={i} className={`price-row ${cur_price == price.price ? 'active' : ''} ${price.special ? 'discount-scale' : ''}`}>
                      <ul>
                        <li>{i18n.t("from_qty")} {price.qty} {i18n.t("pcs")}</li>
                        <li>
                          <span className="old-price-li">{price.old_price && price.old_price.toFixed(2) + ' €'}</span>
                          {price.price.toFixed(2)} €
                        </li>
                      </ul>
                      {
                        price.special ? 
                        <Sale2Svg className='discount-scale-icon' color="#009688"/> : ''
                      }
                      {
                        price.special ? 
                        <span className='discount-scale-reason'>{i18n.t('discount_scale_reason_text')}</span> : ''
                      }
                    </li>
                  )
                })
              }
              </ul>
              :
              ''
            }
            
          </div>
        }
      </div>
    );
  }
}

export default ProductPrices;