
import bankersRounding from 'bankers-rounding';

class Utils {
  constructor() {
    
  }

  round(n, d = 2) {
    var x = n * Math.pow(10, d);
    var r = Math.round(x);
    var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r-1) : r;
    return (br / Math.pow(10, d));
  }

  getBrandImage(id) {
    if(id == "1"){
      return 'K PRIME.png';
    }else if(id == "2"){
      return 'FAGEO.png';
    }else if(id == "3"){
      return 'KEYA.png';
    }else if(id == "4"){
      return 'GILDAN.png';
    }else if (id == "5"){
      return 'FOTL.png';
    }
    return false;
  }
}

const utils = new Utils();

export default utils;